import { Box, Container, Toolbar, Typography, IconButton } from '@mui/material';
import MuiAppBar from '@mui/material/AppBar';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useNavigate } from 'react-router-dom';

export default function BackButtonBar() {

    const navigate = useNavigate();

    return (
        <MuiAppBar position="static">
            <Toolbar style={{ margin: '0px 20px' }}>
                <Container sx={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                    <Box display="flex" gap={1} alignItems="center" width="15%">
                        <IconButton sx={{ color: "white" }} onClick={() => navigate(-1)}>
                            <ArrowBackIcon />
                        </IconButton>
                        <Box style={{ cursor: 'pointer' }}>
                            <Typography variant="h6">Voltar</Typography>
                        </Box>
                    </Box>
                </Container>
            </Toolbar>
        </MuiAppBar>
    )
}