import { ReactNode, createContext, useState } from "react";
import { jwtDecode } from "jwt-decode";

export interface UserData {
    id: string,
    name: string;
    group: GroupProps;
    is_admin: Boolean;
    groups: {
        id: number;
        name: string;
    }[],
}

export interface AuthContextType {
    isAuth: boolean;
    updateToken: (token: string) => void;
    logout: () => void;
    getUserData: () => UserData | null;
    token: string | null
}

export const AuthContext = createContext<AuthContextType>({
    isAuth: false,
    updateToken: (token: string) => {},
    logout: () => null,
    getUserData: () => null,
    token: ""
});

type GroupProps = {
    id: number;
    name: string;
}

export function AuthProvider({ children }: { children: ReactNode }) {

    const [token, setToken] = useState(localStorage.getItem("token"));

    const isAuth = token ? true : false;

    const logout = () => {
        localStorage.removeItem("token");
        setToken(null);
    }

    const updateToken = (token: string) => {
        localStorage.setItem("token", token);
        setToken(token);
    }

    const getUserData = () => {
        if (token) {
            let decoded: any = jwtDecode(token);
            let group: GroupProps = decoded.groups.find((e: any) => e.id == decoded.group_selected);

            return {
                id: decoded.user?.id,
                name: decoded.user?.name,
                is_admin: decoded.is_admin,
                group: group,
                groups: decoded.groups
            }
        }

        return null;
    }

    return (
        <AuthContext.Provider value={{ isAuth, updateToken, logout, getUserData, token }}>
            {children}
        </AuthContext.Provider>
    )
}