import { lazy } from "react";
import { Navigate, RouteObject } from "react-router-dom";
import { AuthenticatedLayout } from "../components/AuthenticatedLayout";

const Flows = lazy(() => import("../screens/Flows"));
const Processes = lazy(() => import("../screens/Processes"));
const Settings = lazy(() => import("../screens/Settings"));
const Tasks = lazy(() => import("../screens/Tasks"));
const FlowDetail = lazy(() => import("../screens/Settings/Flows/Detail"));

export default function privateRoutes(): RouteObject {

  return {
    element: <AuthenticatedLayout />,
    children: [
      { path: "/tasks", element: <Tasks />},
      { path: "/settings/:tab?", element: <Settings /> },
      { path: "/flows", element: <Flows /> },
      { path: "/processes", element: <Processes /> },
      { path: "/flow/:id", element: <FlowDetail /> },
      { path: "*", element: <Navigate to="/tasks" replace /> },
    ]
  }

}