import { useContext } from 'react';
import './App.css';
import { ThemeProvider, createTheme } from '@mui/material';
import { QueryClient, QueryClientProvider } from 'react-query';
import { blue, yellow, amber } from '@mui/material/colors';
import { AuthContext } from './contexts/AuthContext';
import { RouterProvider, createBrowserRouter } from 'react-router-dom';
import publicRoutes from './routes/public';
import { SnackbarProvider } from 'notistack';
import { CompanyProvider } from './contexts/CompanyContext';
import privateRoutes from './routes/private';

const twentyFourHoursInMs = 1000 * 60 * 60 * 24;

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      staleTime: twentyFourHoursInMs,
    },
  },
});

const defaultTheme = createTheme({
  palette: {
    background: {
      default: '#F5F5F5'
    },
    primary: {
      main: '#0b2643',
    },
    secondary: yellow
  }
});

function App() {

  const { isAuth } = useContext(AuthContext);

  const router = createBrowserRouter([
    isAuth ? privateRoutes() : {}, ...publicRoutes(),
  ]);

  return (
    <SnackbarProvider maxSnack={6} autoHideDuration={8000}>
      <QueryClientProvider client={queryClient}>
        <CompanyProvider>
            <ThemeProvider theme={defaultTheme}>
              <RouterProvider router={router}/>
            </ThemeProvider>
        </CompanyProvider>
      </QueryClientProvider>
    </SnackbarProvider>
  );
}

export default App;
