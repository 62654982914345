import { CircularProgress, CssBaseline, Typography } from "@mui/material";
import { Suspense } from "react";
import { Outlet, useLocation, matchPath } from "react-router-dom";
import AppBar from "./AppBar";
import BackButtonBar from "./BackButtonBar";

export function AuthenticatedLayout() {

  const location = useLocation();
  const match = matchPath("/flow/:id", location.pathname);

  return (
    <>
      {match ? <BackButtonBar /> : <AppBar />}

      <Suspense fallback={<CircularProgress />}>
        <CssBaseline />
        <Outlet />
      </Suspense>
    </>
  );
}