import {
    Autocomplete,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    TextField,
  } from "@mui/material";
  import { useState } from "react";
  
  type ProfileProps = {
    id: number;
    name: string;
  };
  
  type LinkGroupModalProps = {
    open: boolean;
    onClose: () => void;
    onSubmit: (group: ProfileProps) => void;
    profiles: ProfileProps[];
  };
  
  const ChangeProfileModal = ({ open, onClose, onSubmit, profiles }: LinkGroupModalProps) => {
    const [selectedProfile, setSelectedProfile] = useState<ProfileProps | null>(null);
  
    return (
      <Dialog open={open} onClose={onClose} fullWidth>
        <DialogTitle>Selecionar Perfil</DialogTitle>
        <DialogContent>
          <Autocomplete
            id="group"
            options={profiles}
            getOptionLabel={(group) => group.name}
            renderInput={(params) => (
              <TextField
                {...params}
                margin="normal"
                label="Nome do perfil"
                InputProps={{
                  ...params.InputProps,
                }}
              />
            )}
            onChange={(event, newValue) => setSelectedProfile(newValue as ProfileProps)}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose}>Cancelar</Button>
          <Button onClick={() => {
            onSubmit(selectedProfile as ProfileProps)
            onClose();
          }} color="primary" variant="contained">
            Selecionar
          </Button>
        </DialogActions>
      </Dialog>
    );
  };
  
  export default ChangeProfileModal;
  