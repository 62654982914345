import {
  Box,
  CircularProgress,
  CssBaseline,
  Grid,
  TextField,
  Typography,
  IconButton,
  FormControl,
  List,
  ListItem,
  Button,
  Paper,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import FileUploadOutlinedIcon from "@mui/icons-material/FileUploadOutlined";
import { Controller, FormProvider, SubmitHandler, useForm } from "react-hook-form";
import { useQuery, useMutation } from "react-query";
import { useNavigate, useParams } from "react-router-dom";
import { useContext, useState } from "react";
import axiosClient from "../../../libs/axios";
import { CompanyContext } from "../../../contexts/CompanyContext";
import ImageNotFound from "../../../assets/image-not-found.jpg";
import { DynamicFormRender } from "../../../components/DynamicForm/DynamicFormRender";
import { enqueueSnackbar } from "notistack";
import ReCAPTCHA from "react-google-recaptcha";
//6LcxEoMqAAAAANkhvk4YH-qkUIM-25SaB48WkRE6

interface FormValues {
  flow?: {
    id: number;
    name: string;
    version: number;
    start_rules: {
      attachment_required: boolean;
      observation_required: boolean;
      form_required: boolean;
      form_components?: any[];
    };
  };
  observation: string;
  files: File[];
  form_response?: any;
}

export default function CreateProcess() {
  const navigate = useNavigate();
  const { identifier } = useParams<{ identifier: string }>();
  const [name, setName] = useState<string>("");
  const [isSubmitted, setIsSubmitted] = useState<boolean>(false);
  const [capVal, setCapval] = useState<any>(null);

  const methods = useForm<FormValues>({
    mode: "onSubmit",
    reValidateMode: "onChange",
    defaultValues: {
      observation: "",
      files: [],
    },
  });

  const { logo } = useContext(CompanyContext);

  const { data, isLoading, isError } = useQuery(
    ["flow", identifier],
    () => axiosClient.get(`/public/detail-flow/${identifier}`),
    {
      enabled: !!identifier,
      onSuccess: (data: any) => {
        setIsSubmitted(true);
        setName(data.data.name);
        methods.setValue("flow", data.data);
      },
      onError: () => {
        enqueueSnackbar("Fluxo não encontrado", { variant: "error" })
      },
    }
  );

  const { mutate, isLoading: isSubmitting } = useMutation(
    (formData: FormData) =>
      axiosClient.post("/public/create-process", formData, {
        headers: { "Content-Type": "multipart/form-data" },
      }),
    {
      onSuccess: () => {
        setCapval(null);
        enqueueSnackbar("Processo criado com sucesso!", { variant: "success" });
        methods.reset();
      },
      onError: (error: any) => {
        setCapval(null);
        const errorMessage = error.response?.data?.message || "Falha ao criar o processo.";
        enqueueSnackbar(errorMessage, { variant: "error" });
      },
    }
  );

  const onSubmit: SubmitHandler<FormValues> = (data) => {

    if (!capVal) {
      enqueueSnackbar("Por favor, complete o ReCAPTCHA.", { variant: "warning" });
      return;
    }

    if (!identifier) {
      enqueueSnackbar("Identificador público inválido.", { variant: "error" })
      return;
    }

    const formData = new FormData();
    formData.append("identifier", identifier);
    formData.append("observation", data.observation);
    formData.append('form_response', JSON.stringify(data.form_response));

    data.files.forEach((file, index) => {
      formData.append(`files[${index}]`, file);
    });

    mutate(formData);
  };

  const handleFileRemove = (index: number): void => {
    const updatedFiles = methods.getValues("files").filter((_, i) => i !== index);
    methods.setValue("files", updatedFiles);
  };

  if (isLoading) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" height="100vh">
        <CircularProgress />
      </Box>
    );
  }

  if (isError) {
    return (
      <Box display="flex" flexDirection="column" alignItems="center" p={2}>
        <img src={ImageNotFound} width={220} alt="Fluxo não encontrado" />
        <Typography variant="h6" mt={2}>
          Fluxo não encontrado
        </Typography>
        <Typography mb={2} textAlign="center">
          O fluxo que você está tentando acessar não existe ou foi removido.
        </Typography>
        <Button variant="contained" onClick={() => navigate(-1)}>
          Voltar
        </Button>
      </Box>
    );
  }

  return (
    <FormProvider {...methods}>
      <Grid container component="main" sx={{ height: "100vh" }}>
        <CssBaseline />
        <Grid item xs={12} container alignItems="center" justifyContent="center">
          <Box
            component={Paper}
            elevation={6}
            square
            p={4}
            py={8}
            sx={{
              display: "flex",
              minWidth: 500,
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <img src={logo || ImageNotFound} alt="Logo" style={{ width: 100, marginBottom: 36 }} />

            <Typography component="h1" variant="h5" mb={2}>
              {name}
            </Typography>
            {!isSubmitted ? (
              <Box component="form" onSubmit={methods.handleSubmit(onSubmit)} sx={{ mt: 1, width: "100%" }}>
                <FormControl fullWidth variant="outlined" margin="normal">
                  <Controller
                    name="observation"
                    control={methods.control}
                    render={({ field }) => (
                      <TextField
                        {...field}
                        label="Observações"
                        variant="outlined"
                        fullWidth
                        multiline
                        rows={4}
                      />
                    )}
                  />
                </FormControl>

                {methods.watch("flow.start_rules.form_required") && (
                  <Box mt={2}>
                    <Controller
                      name="form_response"
                      control={methods.control}
                      rules={{
                        validate: (value) => {
                          const requiredFields = methods.watch("flow.start_rules.form_components")?.filter(
                            (component: any) => component.isRequired
                          );
                          if (!requiredFields || requiredFields.length === 0) return true;
                          const missingFields = requiredFields.filter(
                            (component: any) => !value?.hasOwnProperty(component.id)
                          );
                          return missingFields.length === 0 || "Preencha todos os campos obrigatórios.";
                        },
                      }}
                      render={({ field, fieldState }) => (
                        <>
                          <DynamicFormRender
                            components={methods.watch("flow.start_rules.form_components") || []}
                            onChange={(values: any) => {
                              field.onChange(values);
                            }}
                          />
                          {fieldState.error && (
                            <Typography variant="body2" color="error">
                              {fieldState.error.message}
                            </Typography>
                          )}
                        </>
                      )}
                    />
                  </Box>
                )}

                <Box sx={{ display: "flex", flexDirection: "column", alignItems: "flex-start", mt: 2 }}>
                  {methods.watch("flow.start_rules.attachment_required") && (
                    <Typography variant="subtitle1" color="#d32f2f">
                      Obrigatório*
                    </Typography>
                  )}
                  <Button
                    variant="outlined"
                    component="label"
                    color={methods.watch("flow.start_rules.attachment_required") ? "error" : "primary"}
                    startIcon={<FileUploadOutlinedIcon />}
                  >
                    Anexar arquivos
                    <input
                      type="file"
                      hidden
                      multiple
                      onChange={(e) => {
                        if (e.target.files) {
                          const existingFiles = methods.getValues("files") || [];
                          const newFiles = Array.from(e.target.files);
                          methods.setValue("files", [...existingFiles, ...newFiles]);
                        }
                      }}
                    />
                  </Button>
                </Box>

                <List>
                  {methods.watch("files").map((file: File, index: number) => (
                    <ListItem key={index} sx={{ display: "flex", justifyContent: "space-between" }}>
                      {file.name}
                      <IconButton edge="end" aria-label="delete" onClick={() => handleFileRemove(index)}>
                        <DeleteIcon />
                      </IconButton>
                    </ListItem>
                  ))}
                </List>

                <Button
                  type="submit"
                  fullWidth
                  variant="contained"
                  color="primary"
                  disabled={isSubmitting || !capVal}
                  sx={{ mt: 3, mb: 2 }}
                >
                  {isSubmitting ? <CircularProgress size={24} /> : "Iniciar Processo"}
                </Button>
                <Box display="flex" justifyContent="center">
                  <ReCAPTCHA
                    sitekey="6LcxEoMqAAAAANkhvk4YH-qkUIM-25SaB48WkRE6"
                    onChange={(value: any) => setCapval(value)}
                  />
                </Box>
              </Box>
            ) : (
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "center",
                  height: "100%",
                  textAlign: "center",
                }}
              >
                <Typography variant="h5" gutterBottom>
                  Processo criado com sucesso!
                </Typography>
                <Button
                  variant="outlined"
                  color="primary"
                  onClick={() => setIsSubmitted(false)}
                  sx={{ mt: 2 }}
                >
                  Criar outro processo
                </Button>
              </Box>
            )}
          </Box>
        </Grid>
      </Grid>
    </FormProvider>
  );
}