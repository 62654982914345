import { lazy } from "react";
import { Navigate, RouteObject } from "react-router-dom";
import PublicCreateProcess from "../screens/Public/CreateProcess";

const Login = lazy(() => import("../screens/Login"));

export default function publicRoutes(): Array<RouteObject> {
  return [
    { path: "/login", element: <Login /> },
    { path: "/public/create-process/:identifier", element: <PublicCreateProcess /> },
    { path: "*", element: <Navigate to="/login" replace /> },
  ];
}