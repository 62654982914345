import { Autocomplete, Box, Divider, TextField, Typography } from "@mui/material";
import { useState } from "react";
import useDebounce from "../../hooks/useDebounce";
import { useQuery } from "react-query";
import axiosClient from "../../libs/axios";

type DataSourceAutocompleteProps = {
    uuid: string;
    label: string;
    required?: boolean;
    value: any;
    onChange: (value: any) => void;
};

const formatKey = (key: string): string => {
    return key
        // Substitui underscores por espaços
        .replace(/_/g, ' ')
        // Capitaliza a primeira letra de cada palavra
        .split(' ')
        .map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
        .join(' ');
};

export function DataSourceAutocomplete({ uuid, label, required, value, onChange }: DataSourceAutocompleteProps) {
    const [searchTerm, setSearchTerm] = useState("");
    const debouncedSearch = useDebounce(searchTerm, 500);

    const { data, isLoading } = useQuery({
        queryKey: ['data-source-search', uuid, debouncedSearch],
        queryFn: () =>axiosClient.get(`/data-source/${uuid}/search`, { params: { keyword: debouncedSearch } }),
        enabled: !!uuid
    });

    const options: readonly any[] = (data?.data?.data ?? []) as readonly any[];

    return (
        <Autocomplete
            loading={isLoading}
            loadingText="Carregando..."
            noOptionsText="Nenhuma opção encontrada"
            fullWidth
            options={options}
            getOptionLabel={() => ''}
            filterOptions={(x) => x}
            renderOption={(props, option, state) => (
                <>
                    <li {...props} style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start', gap: 4 }}>
                        {Object.entries(option).map(([key, value], index) => (
                            <Typography
                                key={key}
                                variant="caption"
                                component="div"
                                sx={{ 
                                    display: 'block',
                                    width: '100%'
                                }}
                            >
                                <Typography
                                    component="span"
                                    variant="caption"
                                    sx={{ fontWeight: 'bold' }}
                                >
                                    {formatKey(key)}
                                </Typography>
                                {': '}
                                {String(value)}
                            </Typography>
                        ))}
                    </li>
                    <Divider sx={{ my: 1 }} />
                </>
            )}
            isOptionEqualToValue={(option, value) => {
                if (!option || !value) return false;
                return JSON.stringify(option) === JSON.stringify(value);
            }}
            onInputChange={(_, newValue) => setSearchTerm(newValue)}
            onChange={(_, newValue) => onChange(newValue)}
            value={value || null}
            renderInput={(params) => (
                <TextField
                    {...params}
                    label={label}
                    required={required}
                    variant="outlined"
                    inputProps={{
                        ...params.inputProps,
                        readOnly: !!value,
                    }}
                    InputProps={{
                        ...params.InputProps,
                        sx: value ? {
                            '& .MuiInputBase-input': {
                                cursor: 'default !important',
                                caretColor: 'transparent',
                                userSelect: 'none',
                            },
                        } : {},
                        startAdornment: value ? (
                            <Box
                                sx={{ 
                                    display: 'flex', 
                                    flexDirection: 'column', 
                                    gap: 0.5,
                                    py: 0.5
                                }}
                            >
                                {Object.entries(value).map(([key, val]) => (
                                    <Typography
                                        key={key}
                                        variant="caption"
                                        component="div"
                                    >
                                        <Typography
                                            component="span"
                                            variant="caption"
                                            sx={{ fontWeight: 'bold' }}
                                        >
                                            {formatKey(key)}
                                        </Typography>
                                        {': '}
                                        {String(val)}
                                    </Typography>
                                ))}
                            </Box>
                        ) : null
                    }}
                />
            )}
        />
    );
} 